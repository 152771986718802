import { useMutation } from '@apollo/client';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
// import { Redirect } from 'react-router-dom';

import useAuth from '@contexts/auth.context';
import { LOGIN_GQL } from '@gqls/obrt-gqls';
// import { UserRole } from '@interfaces/user.interface';
import useIsMountedRef from '@hooks/use-is-mounted-ref';
import LoginPresenter from '@pages/login/login.presenter';
import { LogPrefix } from '@utils/log-prefix';
import { GQL } from '@utils/wrap-gql';

const pageTitle = 'Login';
const log = new LogPrefix(`${pageTitle}\t\t\t\t\tContainer\t\t\t\t`);

interface IProps {
  className?: string;
}

const LoginContainer: FC<IProps> = () => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const isMountedRef = useIsMountedRef();
  const history = useHistory();

  const [loginMutation] = useMutation(GQL(LOGIN_GQL));
  // const cache = new InMemoryCache();

  const user = useAuth();

  // if (user.isAuthenticated) {
  //   return <Redirect to="/" />;
  // }

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    // log.info('onSubmit');
    setSubmitting(true);

    await loginMutation({
      variables: {
        input: {
          email: values.email,
          password: values.password,
        },
      },
    })
      .then((response) => {
        log.info(`loginMutation.then - response : `, response);
        const {
          data: {
            login: { data },
          },
        } = response;

        // log.info(`loginMutation.then - data : `, data.userRole);
        user.login(data);
        history.go(-1);

        if (isMountedRef.current) {
          setStatus({ success: true });
          setSubmitting(false);
        }
      })
      .catch((error) => {
        if (isMountedRef.current) {
          setStatus({ success: false });
          setErrors({ submit: 'Email address does not exist or password is incorrect.' });
          setSubmitting(false);
        }
        if (error.message !== 'Unauthorized') {
          console.error(error);
          return [500, { message: 'Internal server error' }];
        }
        // Promise.reject('reject');
      });
  };

  return <LoginPresenter onSubmit={onSubmit} />;
};

export default LoginContainer;
