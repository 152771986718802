import { useLazyQuery } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';

import { GET_ALL_EDUS_GQL } from '@gqls/obrt-gqls';
import { PageInfo } from '@gqls/types';
import { DEFAULT_EDUCATION_CATEGORY } from '@interfaces/education.interface';
import EducationListPresenter from '@pages/education/list/education-list.presenter';
import { LogPrefix } from '@utils/log-prefix';
import { GQL } from '@utils/wrap-gql';

const pageTitle = 'Education List';
const log = new LogPrefix(`${pageTitle}\t\t\tContainer\t\t`);

const EducationListContainer: FC = () => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const ITEM_PER_PAGE = 9;
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    __typename: undefined,
    hasNextPage: undefined,
    hasPreviousPage: undefined,
    startCursor: '',
    endCursor: '',
  });
  const [selectedTab, setSelectedTab] = useState(DEFAULT_EDUCATION_CATEGORY);
  const [prevTab, setPrevTab] = useState('');
  const [results, setResults] = useState([]);

  const [GetAllEdus, { fetchMore }] = useLazyQuery(GQL(GET_ALL_EDUS_GQL), {
    onCompleted: (data) => {
      // log.info(`onCompleted -data.edus.pageInfo: `, data.edus.pageInfo);
      // log.info(`onCompleted -data.edus.edges: `, data.edus.edges);
      if (selectedTab !== prevTab) {
        setPrevTab(selectedTab);
        setPageInfo(data.edus.pageInfo);
        setResults(results.concat(data.edus.edges));
      }
    },
  });

  useEffect(() => {
    // log.info(`useEffect -endCursor : `, pageInfo.endCursor);
    setResults([]);
    setPageInfo({
      __typename: undefined,
      hasNextPage: undefined,
      hasPreviousPage: undefined,
      startCursor: '',
      endCursor: '',
    });

    GetAllEdus({
      variables: {
        // category: selectedTab,
        pagingFirst: ITEM_PER_PAGE,
        pagingAfterCursor: '',
      },
    });
  }, [GetAllEdus]);

  const onFetchMore = () => {
    if (fetchMore === undefined) return;
    if (pageInfo.hasNextPage === false) return;

    fetchMore({
      variables: {
        // category: selectedTab,
        pagingFirst: ITEM_PER_PAGE,
        pagingAfterCursor: pageInfo.endCursor,
      },
    }).then((response) => {
      // log.info(`onFetchMore.then -response : `, response);
      setPageInfo(response.data.edus.pageInfo);
      setResults(results.concat(response.data.edus.edges));
    });
  };

  return (
    <EducationListPresenter
      onFetchMore={onFetchMore}
      pageInfo={pageInfo}
      results={results}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
    />
  );
};

export default EducationListContainer;
