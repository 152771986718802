import clsx from 'clsx';
import { AppBar, Container, makeStyles } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';

import Footer from '@layouts/footer';
// import NavBarPresenter from '@layouts/nav-bar/nav-bar.presenter';
import TopBarContainer from '@layouts/top-bar/top-bar.container';
import { Theme } from '@src/theme';
import { LogPrefix } from '@utils/log-prefix';
import { THEME_TYPES } from '@constants/theme.constant';

const log = new LogPrefix('Layout\t\t\t\t\tㅁㄴ  Main  ㄱㅁ\t\t\t');

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEME_TYPES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEME_TYPES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

interface IProps {
  children?: ReactNode;
}

const MainLayout: FC<IProps> = ({ children }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const classes = useStyles();
  // const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <AppBar className={clsx(classes.appBar)}>
        <Container maxWidth="lg">
          <TopBarContainer />
        </Container>
      </AppBar>
      {/* <Hidden lgUp>
        <NavBarPresenter
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      </Hidden> */}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
