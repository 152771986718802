import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { Box, Button, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import type { FC } from 'react';

import { TextFieldBox } from '@components/form/form-field-box';
import { ILogin } from '@interfaces/auth.interface';
import { LogPrefix } from '@utils/log-prefix';
import { LogInValidation } from '@validation/auth.validation';

const pageTitle = 'Login';
const log = new LogPrefix(`${pageTitle}\t\t\t\t\t  ㄴㅡ Form\t\t\t\t`);

interface IProps {
  className?: string;
  onSubmit: (value: any, formikBag: any) => void;
}

const LoginForm: FC<IProps> = ({ className, onSubmit, ...rest }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const initialValues: ILogin = {
    email: '',
    password: '',
  };

  const render = (formikBag) => (
    <Form className={clsx(className)} {...rest}>
      {formikBag.errors.submit && (
        <Box mt={1} mb={2}>
          <Alert severity="error">
            <FormHelperText error>{formikBag.errors.submit}</FormHelperText>
          </Alert>
        </Box>
      )}

      <TextFieldBox
        id="email"
        name="email"
        label="Email address"
        variant="outlined"
        autoFocus={true}
        pb={4}
      />
      <TextFieldBox
        id="password"
        name="password"
        label="password"
        variant="outlined"
        type="password"
      />

      <Box mt={2}>
        <Button
          id="submit"
          color="secondary"
          disabled={formikBag.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Login
        </Button>
      </Box>
    </Form>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LogInValidation}
      onSubmit={onSubmit}
      // render={render}
    >
      {(props) => render(props)}
    </Formik>
  );
};

export default LoginForm;
