import * as log from 'loglevel';

export class LogPrefix {
  [x: string]: any;
  prefix: string;
  constructor(prefix: string) {
    this.prefix = prefix;
    log.setDefaultLevel('info');
  }

  info(...msg: any) {
    return log.info(this.prefix, ...msg);
  }
}
