import clsx from 'clsx';
import { Box, Typography, makeStyles, Grid, Container, Hidden } from '@material-ui/core';
import React, { FC } from 'react';

import { Theme } from '@src/theme';
import { LogPrefix } from '@utils/log-prefix';

const log = new LogPrefix('Layout\t\t\t\t\tㅁㄴ  Footer  ㄱㅁ\t\t');

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    backgroundColor: '#2A2A2A',
    // height: '158px',
    paddingTop: theme.spacing(2),
    '@media print': {
      display: 'none',
    },
  },
  container: {
    // height: '30px',
    paddingBottom: theme.spacing(1),
  },
  font: {
    color: '#ffffff',
    fontSize: '1.0rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem',
    },
  },
}));

interface IProps {
  className?: string;
}

const Footer: FC<IProps> = ({ className }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="lg">
        <Grid container className={classes.container} alignItems="center" justify="flex-start">
          <Box display="flex">
            <Box>
              <Typography variant="body2" className={classes.font}>
                대표번호 : 010-7751-7238 박주형
              </Typography>
            </Box>
            <Box paddingX="10px">
              <Typography variant="body2" className={classes.font}>
                {' '}
                |{' '}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" className={classes.font}>
                E-mail : parkjh@cju.ac.kr
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid container className={classes.container} alignItems="center" justify="flex-start">
          <Box display="flex">
            <Box>
              <Typography variant="body2" className={classes.font}>
                청주대학교 산학협력단
              </Typography>
            </Box>
            <Box paddingX="10px">
              <Typography variant="body2" className={classes.font}>
                {' '}
                |{' '}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" className={classes.font}>
                사업자번호 : 301-82-14193
              </Typography>
            </Box>
            <Hidden xsDown>
              <Box paddingX="10px">
                <Typography variant="body2" className={classes.font}>
                  {' '}
                  |{' '}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" className={classes.font}>
                  주소 : 충청북도 청주시 청원구 대성로 298, 1층(내덕동, 청주대학교)
                </Typography>
              </Box>
            </Hidden>
          </Box>
        </Grid>

        <Grid container className={classes.container} alignItems="center" justify="flex-start">
          <Box display="flex">
            <Typography variant="body2" className={classes.font}>
              본 홈페이지는 2018년도 정부(과학기술정보통신부)의 재원으로 한국연구재단의 지원을 받아
              수행된 연구임(No. 2018R1C1B508645113)
            </Typography>
          </Box>
        </Grid>

        <Grid container className={classes.container} alignItems="center" justify="flex-start">
          <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
            <img
              alt="크리에이티브 커먼즈 라이선스"
              style={{ borderWidth: 0 }}
              src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png"
            />
          </a>
          <Hidden smDown>
            <Box ml={2} />
            <Typography variant="body2" className={classes.font}>
              이 저작물은&nbsp;
            </Typography>
            <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
              <Typography variant="body2" className={classes.font}>
                크리에이티브 커먼즈 저작자표시-비영리-변경금지 4.0 국제 라이선스
              </Typography>
            </a>
            <Typography variant="body2" className={classes.font}>
              에 따라 이용할 수 있습니다.
            </Typography>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
