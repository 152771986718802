import React, { createContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { ThemeOptions } from '@material-ui/core';
import { storeSettings } from '@utils/settings';
import { IThemeSettings } from '@interfaces/theme.interface';
import { ThemesEnum } from '@constants/theme.constant';

interface IProps {
  settings: IThemeSettings;
}

const defaultSettings: IThemeSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: ThemesEnum.ONE_DARK,
};

const SettingsContext = createContext({});

export const SettingsProvider: React.FC<IProps> = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);

  const handleSaveSettings = (updatedSettings: ThemeOptions = {}): void => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction as string;
  }, [currentSettings.direction]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
