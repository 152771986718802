import React, { FC } from 'react';

import TopBarPresenter from '@layouts/top-bar/top-bar.presenter';
import { LogPrefix } from '@utils/log-prefix';

const pageTitle = 'Top Bar';
const log = new LogPrefix(`${pageTitle}\t\t\t\t\tContainer\t\t\t\t`);

interface IProps {
  className?: string;
  onMobileNavOpen?: () => void;
}
const TopBarContainer: FC<IProps> = ({ onMobileNavOpen }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  return <TopBarPresenter onMobileNavOpen={onMobileNavOpen} />;
};

export default TopBarContainer;
