import clsx from 'clsx';
import { Box, Button, Container, Typography, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import useAuth from '@contexts/auth.context';
import { UserRole } from '@gqls/types';
import { EducationCategory } from '@interfaces/education.interface';
import { Theme } from '@src/theme';
import { LogPrefix } from '@utils/log-prefix';

const pageTitle = 'Education List';
const log = new LogPrefix(`${pageTitle}\t\t\t  ㄴㅡ Header\t\t`);

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  cover: {
    position: 'relative',
    height: 360,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '&:before': {
      position: 'absolute',
      content: '" "',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundImage: 'linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)',
    },
  },
  action: {
    marginLeft: theme.spacing(1),
  },
}));

interface IProps {
  className?: string;
  user: any;
  currentTab: EducationCategory;
}

const EducationListHeader: FC<IProps> = ({ className, user, currentTab, ...rest }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const classes = useStyles();
  const { userRole } = useAuth();

  const imgUrl = '/static/images/hands-1920.jpg';

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={classes.cover} style={{ backgroundImage: `url(${imgUrl})` }} />
      {(userRole === UserRole.Admin || userRole === UserRole.Super) && (
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center" mt={3}>
            <Typography variant="h4" color="textPrimary">
              E-education
            </Typography>

            <Box flexGrow={1} />
            <Button
              color="secondary"
              component={RouterLink}
              size="small"
              to="/education/create"
              variant="contained"
              className={classes.action}
            >
              강좌 업로드
            </Button>
          </Box>
        </Container>
      )}
    </div>
  );
};

export default EducationListHeader;
