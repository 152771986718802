import clsx from 'clsx';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  Button,
} from '@material-ui/core';
import React from 'react';
import type { FC } from 'react';

import { LogPrefix } from '@utils/log-prefix';

const pageTitle = 'Login';
const log = new LogPrefix(`${pageTitle}\t\t\t\t\t  ㄴㅡ Find Password\t\t`);

interface IProps {
  className?: string;
  isOpen: boolean;
  handleClose: () => void;
}
const FindPasswordDialog: FC<IProps> = ({ className, isOpen, handleClose, ...rest }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  return (
    <Dialog
      className={clsx(className)}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="findPassword-dialog-title"
      aria-describedby="findPassword-dialog-description"
      {...rest}
    >
      <DialogTitle id="findPassword-title">
        <Box width="100%" textAlign="center">
          Find Password
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Box pb={1}>* 관리자 계정의 경우 개발자에게 문의바랍니다.</Box>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Box width="100%" textAlign="center">
          <Button variant="contained" color="secondary" onClick={handleClose}>
            확인
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FindPasswordDialog;
