import React from 'react';
import { Container, makeStyles } from '@material-ui/core';

import Page from '@components/page';
import EducationEditHeader from '@pages/education/edit/EducationEditHeader';
import EducationEditForm from '@pages/education/edit/education-edit.form';
import { LogPrefix } from '@utils/log-prefix';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100,
  },
}));

interface IPresenterProps {
  className?: string;
  onSubmit: (value: any, formikBag: any) => void;
  editor: any;
  eduData: any;
}

const log = new LogPrefix('Education Edit Presenter \t-');

const EducationEditPresenter: React.FC<IPresenterProps> = ({ onSubmit, editor, eduData }) => {
  const classes = useStyles();

  log.info(`start`);

  return (
    <Page className={classes.root} title={`OBRT :: Product Create`}>
      <Container maxWidth="lg">
        <EducationEditHeader />
        <EducationEditForm onSubmit={onSubmit} editor={editor} eduData={eduData} />
      </Container>
    </Page>
  );
};

export default EducationEditPresenter;
