import clsx from 'clsx';
import {
  Avatar,
  Button,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Link,
  Typography,
  colors,
  makeStyles,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import React, { useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Logo from '@components/logo';
import Page from '@components/page';
import FindPasswordDialog from '@pages/login/find-password.dialog';
import LoginForm from '@pages/login/login.form';
import { LogPrefix } from '@utils/log-prefix';
import { Theme } from '@src/theme';

const pageTitle = 'Login';
const log = new LogPrefix(`${pageTitle}\t\t\t\t\tㄴㅡ Presenter\t\t\t`);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: colors.green[500],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

interface IProps {
  className?: string;
  onSubmit: (value: any, formikBag: any) => void;
}

const LoginPresenter: FC<IProps> = ({ className, onSubmit, ...rest }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const classes = useStyles();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Page className={clsx(classes.root, className)} {...rest} title={`OBRT :: Login`}>
      <Container maxWidth="md">
        <Box mb={8} display="flex" alignItems="center">
          <RouterLink to="/">
            <Logo width={120} />
          </RouterLink>
          <Button component={RouterLink} to="/">
            Back to home
          </Button>
        </Box>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockIcon fontSize="large" />
            </Avatar>
            <Typography variant="h2" color="textPrimary">
              Login
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Login on the internal platform
            </Typography>
            {/* <Box mt={2}>
              <Alert severity="info">
                <div>
                  Use <b>admin@gmail.com</b> and password <b>12345678</b>
                </div>
              </Alert>
            </Box> */}
            <Box mt={2}>
              <LoginForm onSubmit={onSubmit} />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              {/* <Link component={RouterLink} to="/signup" variant="body2" color="textSecondary">
                Create new account
              </Link> */}
              {/* <Box flexGrow={1} /> */}
              <Button
                // variant="contained"
                color="secondary"
                startIcon={<LockIcon fontSize="small" />}
                onClick={handleOpen}
              >
                Find Password
              </Button>
            </Box>
          </CardContent>
          <CardMedia className={classes.media} image="/static/images/login.jpg" title="Cover">
            {/* <Typography color="inherit" variant="subtitle1">
              Hella narvwhal Cosby sweater McSweeney&apos;s, salvia kitsch before they sold out High
              Life.
            </Typography>
            <Box alignItems="center" display="flex" mt={3}>
              <Avatar alt="Person" src="/static/images/avatars/avatar_2.png" />
              <Box ml={3}>
                <Typography color="inherit" variant="body1">
                  Ekaterina Tankova
                </Typography>
                <Typography color="inherit" variant="body2">
                  Manager at inVision
                </Typography>
              </Box>
            </Box> */}
          </CardMedia>
        </Card>
      </Container>
      <FindPasswordDialog isOpen={isOpen} handleClose={handleClose} />
    </Page>
  );
};

export default LoginPresenter;
