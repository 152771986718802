import clsx from 'clsx';
import { Box, ButtonBase, Menu, MenuItem, Typography, makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { FC, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useAuth from '@contexts/auth.context';
import { Theme } from '@src/theme';
import { LogPrefix } from '@utils/log-prefix';

const pageTitle = 'Top Bar';
const log = new LogPrefix(`${pageTitle}\t\t\t\t\t  ㄴㅡ Account Menu \t\t`);

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  // avatar: {
  //   height: 32,
  //   width: 32,
  //   marginRight: theme.spacing(1),
  // },
  popover: {
    marginTop: theme.spacing(2),
    width: 230,
  },
}));

interface IProps {
  className?: string;
}

const AccountMenu: FC<IProps> = ({ className }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const classes = useStyles();
  const history = useHistory();
  const ref = useRef<any>(null);
  const { isAuthenticated, logout } = useAuth();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  // const handleLogout = async (): Promise<void> => {
  const handleLogout = () => {
    handleClose();
    logout();
    history.push('/');
  };

  return (
    <div className={clsx(classes.root, className)}>
      {isAuthenticated ? (
        <div>
          <Box
            display="flex"
            alignItems="center"
            component={ButtonBase}
            onClick={handleOpen}
            // @ts-ignore
            ref={ref}
          >
            {/* <Hidden smDown> */}
            <Box>
              <Typography variant="h5" color="inherit">
                {/* {`${userEmail}`} */}
                Admin
              </Typography>
            </Box>
            <ArrowDropDownIcon />
            {/* </Hidden> */}
          </Box>
          <Menu
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            keepMounted
            PaperProps={{ className: classes.popover }}
            getContentAnchorEl={null}
            anchorEl={ref.current}
            open={isOpen}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      ) : null}
    </div>
  );
};

export default AccountMenu;
