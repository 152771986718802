import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// guard
// import AdminGuard from '@components/guard/admin-guard';
// import AuthGuard from '@components/guard/auth-guard';

// import LoadingScreen from '@components/loading-screen';

// layouts
import MainLayout from '@layouts/main-layout/main-layout';

// pages
import NotFoundView from '@pages/404/not-found-view';
import ForbiddenView from '@pages/403/forbidden-view';
import ComingSoon from '@pages/coming-soon/coming-soon';

// education
import EducationList from '@pages/education/list/education-list.container';
import EducationCreate from '@pages/education/create/education-create.container';
import EducationDetail from '@pages/education/detail/education-detail.container';
import EducationEdit from '@pages/education/edit/education-edit.container';

// import Home from '@pages/home/home';
import Login from '@pages/login/login.container';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Switch>
    {routes.map((route, i) => {
      const Guard = route.guard || Fragment;
      const Layout = route.layout || Fragment;
      const Component = route.component;

      return (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          render={(props) => (
            <Guard>
              <Layout>
                {/* {console.log(`routes >>> route.routes : `, route.routes)} */}
                {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
              </Layout>
            </Guard>
          )}
        />
      );
    })}
  </Switch>
);

const routes: Routes = [
  {
    exact: true,
    path: '/coming-soon',
    layout: MainLayout,
    component: ComingSoon,
  },
  {
    exact: true,
    path: '/404',
    layout: MainLayout,
    component: NotFoundView,
  },
  {
    exact: true,
    path: '/403',
    layout: MainLayout,
    component: ForbiddenView,
  },
  {
    exact: true,
    path: '/login',
    // guard: GuestGuard,
    layout: MainLayout,
    component: Login,
  },
  {
    path: '/education',
    // guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/education/',
        component: () => <Redirect to="/education/list" />,
      },
      {
        exact: true,
        path: '/education/list',
        component: EducationList,
      },
      {
        exact: true,
        // guard: AdminGuard,
        path: '/education/create',
        component: EducationCreate,
      },
      {
        exact: true,
        path: '/education/:id',
        component: EducationDetail,
      },
      {
        exact: true,
        // guard: AdminGuard,
        path: '/education/edit/:id',
        component: EducationEdit,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        // component: Home,
        // component: EducationList,
        component: () => <Redirect to="/education/list" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
