import MomentUtils from '@date-io/moment';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import type { FC } from 'react';
import { Router } from 'react-router-dom';

// import { AuthProvider } from '@contexts/auth.context';
import GlobalStyles from '@components/global-styles';
import ScrollReset from '@components/scroll-reset';
import useSettings from '@src/hooks/use-settings';
import routes, { renderRoutes } from '@src/routes';
import { createTheme } from '@src/theme';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            // dense
            maxSnack={5}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Router history={history}>
              {/* <AuthProvider> */}
              <GlobalStyles />
              <ScrollReset />
              {/* <SettingsNotification /> */}
              {renderRoutes(routes)}
              {/* </AuthProvider> */}
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;

// const useSnackbarStyle = makeStyles((theme) => ({
//   //top, left 값을 조정하면 위치를 변경할 수 있다.
//   containerRoot: {
//     top: '78px',
//     left: '12px',
//     bottom: 'auto',
//   },
// }));
