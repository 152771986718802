import React from 'react';
import { Field } from 'formik';
import moment from 'moment';
import 'moment/locale/ko';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Star as StarIcon } from 'react-feather';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  disabledInputStyle: {
    '& .Mui-disabled': {
      color: '#000000',
    },
  },
  disabledSelectStyle: {
    color: '#000000',
  },
  selectStyle: {
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: '#ffffff',
    },
  },
}));

const RequiredLabel = ({ title, required }: { title: string; required: boolean }) => {
  return (
    <Typography variant="body2" color="textSecondary">
      {title} {required && <StarIcon size={9} style={{ stroke: 'red', fill: 'red' }} />}
    </Typography>
  );
};

interface ITextFieldBoxProps {
  autoFocus?: boolean;
  disable?: boolean;
  inputProps?;
  id?: string;
  label?: string;
  name: string;
  pb?: number;
  placeholder?: string;
  pt?: number;
  required?: boolean;
  size?: 'small' | 'medium';
  type?: string;
  variant?: 'filled' | 'standard' | 'outlined';
}

export const TextFieldBox: React.FC<ITextFieldBoxProps> = ({
  autoFocus = false,
  disable,
  inputProps,
  id,
  label,
  name,
  pb = 3,
  placeholder = '',
  pt = 0,
  required = false,
  size = 'medium',
  type = 'text',
  variant = 'standard',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box pb={pb} pt={pt}>
      <Field name={name}>
        {({ field: { value, ...rest }, meta }) => (
          <TextField
            autoFocus={autoFocus}
            classes={{ root: classes.disabledInputStyle }}
            disabled={disable}
            error={Boolean(meta.touched && meta.error)}
            fullWidth
            helperText={meta.touched && meta.error}
            id={id}
            inputProps={inputProps}
            label={label === undefined ? null : <RequiredLabel title={label} required={required} />}
            placeholder={placeholder}
            size={size}
            type={type}
            // inputProps={{ inputmode: 'numeric', pattern: '[0-9]*' }}
            variant={variant}
            value={value || ''}
            {...rest}
          />
        )}
      </Field>
    </Box>
  );
};

export const DateFieldBox: React.FC<ITextFieldBoxProps> = ({
  name,
  label,
  autoFocus = false,
  disable,
  variant = 'standard',
  type = 'text',
  required = false,
  inputProps,
  size = 'medium',
  pb = 3,
}) => {
  const classes = useStyles();

  return (
    <Box pb={pb}>
      <Field name={name}>
        {({ field: { value, ...rest }, meta }) => (
          <TextField
            autoFocus={autoFocus}
            error={Boolean(meta.touched && meta.error)}
            fullWidth
            helperText={meta.touched && meta.error}
            label={label === undefined ? null : <RequiredLabel title={label} required={required} />}
            type={type}
            disabled={disable}
            // inputProps={{ inputmode: 'numeric', pattern: '[0-9]*' }}
            inputProps={inputProps}
            variant={variant}
            size={size}
            classes={{ root: classes.disabledInputStyle }}
            value={moment(value).format('YYYY.MM.DD') || ''}
            {...rest}
          />
        )}
      </Field>
    </Box>
  );
};

interface ISelectBox extends ITextFieldBoxProps {
  children: React.ReactNode;
}

export const SelectBox: React.FC<ISelectBox> = ({
  name,
  label,
  autoFocus = false,
  disable,
  required = false,
  pb = 3,
  variant = 'standard',
  children,
  pt = 0,
}) => {
  const classes = useStyles();

  return (
    <Box pb={pb} pt={pt}>
      <Field name={name}>
        {({ field: { value, ...rest }, meta }) => (
          <FormControl fullWidth classes={{ root: classes.selectStyle }}>
            {label === undefined ? null : (
              <InputLabel id={`${name}-label`}>
                <RequiredLabel title={label} required={required} />
              </InputLabel>
            )}
            <Select
              labelId={`${name}-label`}
              error={Boolean(meta.touched && meta.error)}
              fullWidth
              autoFocus={autoFocus}
              disabled={disable}
              classes={{ disabled: classes.disabledSelectStyle }}
              value={value || ''}
              variant={variant}
              {...rest}
            >
              {children}
            </Select>
            {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
          </FormControl>
        )}
      </Field>

      {/* 양근이 버젼 */}
      {/* <Field name="incoterms">
        {({ field, meta }) => (
          <FormControl fullWidth>
            <InputLabel id="incoterms">
              <RequiredLabel title="Incoterms" required={true} />
            </InputLabel>
            <NativeSelect
              variant="outlined"
              error={Boolean(meta.touched && meta.error)}
              disabled={disable}
              {...field}
            >
              {Object.keys(Incoterms).map((item, index) => (
                <option key={index} value={item}>
                  {Incoterms[item]}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        )}
      </Field> */}
    </Box>
  );
};
