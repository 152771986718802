export enum EducationCategory {
  EDUCATION_CATEGORY_1 = 'EDUCATION_CATEGORY_1',
  // FINDING_BUSINESS_PARTNERS = 'FINDING_BUSINESS_PARTNERS',
  // INTERNET_MARKETING = 'INTERNET_MARKETING',
  // EXPORT_COST_AND_TERMS_NEGOTIATION = 'EXPORT_COST_AND_TERMS_NEGOTIATION',
  // TRADE_INFORMATION = 'TRADE_INFORMATION',
}

export enum EducationCategoryKr {
  EDUCATION_CATEGORY_1 = '주제별 영상 및 지침',
  // FINDING_BUSINESS_PARTNERS = '해외시장 진출 전략',
  // INTERNET_MARKETING = '온라인 마케팅',
  // EXPORT_COST_AND_TERMS_NEGOTIATION = '수출원가 계산',
  // TRADE_INFORMATION = '무역정보',
}

export const DEFAULT_EDUCATION_CATEGORY = EducationCategory.EDUCATION_CATEGORY_1;

export interface IEducation {
  id: string;
  title: string;
  videoId: string;
  // videoThumbnailUrl: string;
  thumbnail?: string;
  pdfUrl?: string;
  // views: number;
  // text: string;
  // category: EducationCategory;
  // createdAt: Date;
  // updatedAt: Date;
}

export interface IEducationDetail extends Partial<IEducation> {}

export interface IEducationCreate extends Partial<IEducation> {}

export interface IEducationCategory {
  value: string;
  label: string;
}

export const tempObjects = [
  {
    id: '0',
    title: '1. 내가 살던 고향은',
    videoId: '_i_lPFhj6lw',
    // videoThumbnailUrl: `https://img.youtube.com/vi/${videoId}/0.jpg`,
    pdfUrl:
      'https://prj-obrt.s3.ap-northeast-2.amazonaws.com/1.+%E1%84%82%E1%85%A2%E1%84%80%E1%85%A1+%E1%84%89%E1%85%A1%E1%86%AF%E1%84%83%E1%85%A5%E1%86%AB+%E1%84%80%E1%85%A9%E1%84%92%E1%85%A3%E1%86%BC%E1%84%8B%E1%85%B3%E1%86%AB.pdf',
  },
  {
    id: '1',
    title: '2 수경 식물 화분 만들기',
    videoId: 'kDf2n3xDdNk',
    pdfUrl:
      'https://prj-obrt.s3.ap-northeast-2.amazonaws.com/2.+%E1%84%89%E1%85%AE%E1%84%80%E1%85%A7%E1%86%BC+%E1%84%89%E1%85%B5%E1%86%A8%E1%84%86%E1%85%AE%E1%86%AF+%E1%84%92%E1%85%AA%E1%84%87%E1%85%AE%E1%86%AB+%E1%84%86%E1%85%A1%E1%86%AB%E1%84%83%E1%85%B3%E1%86%AF%E1%84%80%E1%85%B5.pdf',
  },
  {
    id: '2',
    title: '3 소망꽃 만들기',
    videoId: '9GNG4wnvYcw',
    pdfUrl:
      'https://prj-obrt.s3.ap-northeast-2.amazonaws.com/3.+%E1%84%89%E1%85%A9%E1%84%86%E1%85%A1%E1%86%BC%E1%84%81%E1%85%A9%E1%86%BE+%E1%84%86%E1%85%A1%E1%86%AB%E1%84%83%E1%85%B3%E1%86%AF%E1%84%80%E1%85%B5.pdf',
  },
  {
    id: '3',
    title: '4 한지 찻잔 만들기',
    videoId: 'NUABQcgCUVE',
    pdfUrl:
      'https://prj-obrt.s3.ap-northeast-2.amazonaws.com/4.+%E1%84%92%E1%85%A1%E1%86%AB%E1%84%8C%E1%85%B5+%E1%84%8E%E1%85%A1%E1%86%BA%E1%84%8C%E1%85%A1%E1%86%AB+%E1%84%86%E1%85%A1%E1%86%AB%E1%84%83%E1%85%B3%E1%86%AF%E1%84%80%E1%85%B5.pdf',
  },
  {
    id: '4',
    title: '5 서예 활동',
    videoId: 'tTXRq8tjrXE',
    pdfUrl:
      'https://prj-obrt.s3.ap-northeast-2.amazonaws.com/5.+%E1%84%89%E1%85%A5%E1%84%8B%E1%85%A8+%E1%84%92%E1%85%AA%E1%86%AF%E1%84%83%E1%85%A9%E1%86%BC.pdf',
  },
  {
    id: '5',
    title: '6 잔디 인형 만들기',
    videoId: 'Cf3iP6uXceA',
    pdfUrl:
      'https://prj-obrt.s3.ap-northeast-2.amazonaws.com/6.+%E1%84%8C%E1%85%A1%E1%86%AB%E1%84%83%E1%85%B5+%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%92%E1%85%A7%E1%86%BC+%E1%84%86%E1%85%A1%E1%86%AB%E1%84%83%E1%85%B3%E1%86%AF%E1%84%80%E1%85%B5.pdf',
  },
  {
    id: '6',
    title: '7. 동백꽃 브로치',
    videoId: 'cRp5211vcjQ',
    pdfUrl:
      'https://prj-obrt.s3.ap-northeast-2.amazonaws.com/7.+%E1%84%83%E1%85%A9%E1%86%BC%E1%84%87%E1%85%A2%E1%86%A8%E1%84%81%E1%85%A9%E1%86%BE+%E1%84%87%E1%85%B3%E1%84%85%E1%85%A9%E1%84%8E%E1%85%B5+%E1%84%86%E1%85%A1%E1%86%AB%E1%84%83%E1%85%B3%E1%86%AF%E1%84%80%E1%85%B5.pdf',
  },
  {
    id: '7',
    title: '8 딱지 만들기',
    videoId: 'td9VsYN4fUE',
    pdfUrl:
      'https://prj-obrt.s3.ap-northeast-2.amazonaws.com/8.+%E1%84%84%E1%85%A1%E1%86%A8%E1%84%8C%E1%85%B5%E1%84%8E%E1%85%B5%E1%84%80%E1%85%B5%2C+%E1%84%8B%E1%85%B2%E1%86%BE%E1%84%82%E1%85%A9%E1%86%AF%E1%84%8B%E1%85%B5+%E1%84%92%E1%85%AA%E1%86%AF%E1%84%83%E1%85%A9%E1%86%BC.pdf',
  },
  {
    id: '8',
    title: '9 캘리그라피',
    videoId: 'y77LSwBKOAQ',
    pdfUrl:
      'https://prj-obrt.s3.ap-northeast-2.amazonaws.com/9.+%E1%84%8F%E1%85%A2%E1%86%AF%E1%84%85%E1%85%B5%E1%84%80%E1%85%B3%E1%84%85%E1%85%A1%E1%84%91%E1%85%B5+%E1%84%92%E1%85%AA%E1%86%AF%E1%84%83%E1%85%A9%E1%86%BC.pdf ',
  },
];
