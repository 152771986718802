import { IEducationCategory } from '@interfaces/education.interface';

/* eslint-disable import/prefer-default-export */

// export const THEMES = {
//   LIGHT: 'LIGHT',
//   ONE_DARK: 'ONE_DARK',
//   UNICORN: 'UNICORN',
// };

// export enum EducationCategoryEnum {
//   'OVERSEAS_MARKET_SURVEY' = '해외시장조사',
//   'FINDING_BUSINESS_PARTNERS' = '거래선 발굴',
//   'INTERNET_MARKETING' = '인터넷 마테팅 활용',
//   'EXPORT_COST_AND_TERMS_NEGOTIATION' = '수출원가 계산 및 조건협상',
//   'TRADE_INFORMATION' = '무역정보',
// }

// export enum EducationCategoryEnum {
//   OVERSEAS_MARKET_SURVEY = '해외시장조사',
//   FINDING_BUSINESS_PARTNERS = '거래선 발굴',
//   INTERNET_MARKETING = '인터넷 마테팅 활용',
//   EXPORT_COST_AND_TERMS_NEGOTIATION = '수출원가 계산 및 조건협상',
//   TRADE_INFORMATION = '무역정보',
// }

export const EducationCategoryOptions: Array<IEducationCategory> = [
  { value: 'EDUCATION_CATEGORY_1', label: '활동별 설명 영상 및 지침서 다운로드' },
  // { value: 'FINDING_BUSINESS_PARTNERS', label: '해외시장 진출 전략' },
  // { value: 'INTERNET_MARKETING', label: '온라인 마케팅' },
  // { value: 'EXPORT_COST_AND_TERMS_NEGOTIATION', label: '수출원가 계산' },
  // { value: 'TRADE_INFORMATION', label: '무역정보' },
];
