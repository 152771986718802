import * as Yup from 'yup';

import { EducationCategory } from '@interfaces/education.interface';

export const EducationCreateValidation = {
  title: Yup.string().required('Title is required'),
  category: Yup.mixed().oneOf(Object.keys(EducationCategory)),
};

export const EducationEditValidation = {
  title: Yup.string().required('Title is required'),
  category: Yup.mixed().oneOf(Object.keys(EducationCategory)),
};
