import React from 'react';
import type { FC } from 'react';
import { Box } from '@material-ui/core';

interface IProps {
  isWhite?: boolean;
  width?: number | 'auto';
  [key: string]: any;
}

const Logo: FC<IProps> = ({ isWhite = false, width = 'auto', ...rest }) => {
  return (
    <Box pr={3}>
      <img
        alt="Logo"
        src={isWhite ? '/static/images/logo.png' : '/static/images/logo.png'}
        style={{ width: `${width}px` }}
        {...rest}
      />
    </Box>
  );
};

export default Logo;
