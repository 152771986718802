import { ApolloClient, InMemoryCache, createHttpLink, DefaultOptions } from '@apollo/client';
import { setContext } from '@apollo/link-context';

const cache = new InMemoryCache();
const defaultOptions: DefaultOptions = {
  // https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.watchQuery
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const httpLink = createHttpLink({
  uri: 'https://obrt.net/graphql/',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('jwt');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-jwt': token ? `${token}` : '',
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
  defaultOptions: defaultOptions,
});

export default apolloClient;
