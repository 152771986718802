import axios from 'axios';
import React, { useCallback, useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import MagicUrl from 'quill-magic-url';

import * as env from 'env-var';

// 테스트 해본 목록
// https://www.npmjs.com/package/quill-table
// https://github.com/volser/quill-table-ui

interface IQuillProps {
  setEditorText: any;
  initData?: string;
}

// onChange?
// const [editorText, setEditorText] = useState<any>();의 setEditorText를 던지면 됨
export const QuillEditor: React.FC<IQuillProps> = ({ setEditorText, initData = '{}', ...rest }) => {
  //  const { quill, quillRef, Quill } = useQuill()

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block', 'image', 'link'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ]; // remove formatting button]

  const quillEditor = useQuill({
    modules: {
      magicUrl: true,
      blotFormatter: {},
      toolbar: toolbarOptions,
    },
  });

  if (quillEditor.Quill && !quillEditor.quill) {
    // https://github.com/visualjerk/quill-magic-url
    quillEditor.Quill.register('modules/magicUrl', MagicUrl);
    quillEditor.Quill.register('modules/blotFormatter', BlotFormatter);
  }

  const quillImageInsertToEditor = useCallback(
    (url) => {
      console.log('quillImageInsertToEditor');
      // @ts-ignore
      const range = quillEditor.quill.getSelection();
      // @ts-ignore
      quillEditor.quill.insertEmbed(range.index, 'image', url);
    },
    [quillEditor.quill],
  );

  const quillImageSaveToServer = useCallback(
    async (file) => {
      console.log('quillImageSaveToServer');
      const formData = new FormData();
      formData.append('image', file);

      try {
        const result = await axios({
          method: 'post',
          url: 'https://obrt.net/files/upload/image',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log('axios result', result);
        quillImageInsertToEditor(result.data.filename);
      } catch (error) {
        console.log(error);
      }
    },
    [quillImageInsertToEditor],
  );

  // 이미지 파일 선택 다이얼로그
  const quillSelectLocalImage = useCallback(() => {
    console.log('quillSelectLocalImage');
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      // @ts-ignore
      const file = input.files[0];
      quillImageSaveToServer(file)
        .then((reponse) => {
          console.log(reponse);
        })
        .catch((error) => {});
    };
  }, [quillImageSaveToServer]);

  useEffect(() => {
    quillEditor.quill?.on('text-change', (delta, oldDelta, source) => {
      // console.log(quillEditor.quill?.getContents());
      // setEditorText(quillEditor.quill?.getContents());
      // console.log(JSON.stringify(quillEditor.quill?.getContents()));
      setEditorText(JSON.stringify(quillEditor.quill?.getContents()));
      // console.log(editorText);
    });

    if (quillEditor.quill) {
      quillEditor.quill.getModule('toolbar').addHandler('image', quillSelectLocalImage);
      // https://quilljs.com/docs/api/#setcontents

      try {
        quillEditor.quill.setContents(JSON.parse(initData)?.ops);
      } catch (error) {
        console.log(error);
      }
    }
  }, [quillEditor.quill, setEditorText, initData, quillSelectLocalImage]);
  return <div ref={quillEditor.quillRef} />;
};
