import clsx from 'clsx';
import { Box, Typography, makeStyles, Container } from '@material-ui/core';
import React, { FC } from 'react';
import { AlertCircle as AlertCircleIcon } from 'react-feather';

import Page from '@components/page';
import { Theme } from '@src/theme';
import { LogPrefix } from '@utils/log-prefix';

const pageTitle = 'Project List';
const log = new LogPrefix(`${pageTitle}\t-`);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: 'calc(100vh - 64px - 64px)',
  },
}));

interface IProps {
  className?: string;
}

const ComingSoon: FC<IProps> = ({ className }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const classes = useStyles();

  return (
    <Page className={clsx(classes.root, className)} title={`OBRT :: ${pageTitle}`}>
      <Container maxWidth="lg">
        <Box className={classes.root} display="flex" alignItems="center" justifyContent="center">
          <Box display="flex" alignItems="center">
            <AlertCircleIcon size={36} style={{ marginRight: '10px' }} />
            <Typography variant="h2">페이지 준비 중입니다.</Typography>
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default ComingSoon;
