import clsx from 'clsx';
import { Toolbar, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
// import { Menu as MenuIcon } from 'react-feather';
// import { Link as RouterLink } from 'react-router-dom';

// import Logo from '@components/logo';
import AccountMenu from '@layouts/top-bar/account.menu-item';
import TopBarMenu from '@layouts/top-bar/top-bar.menu';
import { Theme } from '@src/theme';
import { LogPrefix } from '@utils/log-prefix';

const pageTitle = 'Top Bar';
const log = new LogPrefix(`${pageTitle}\t\t\t\t\tㄴㅡ Presenter\t\t\t`);

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    minHeight: 64,
    '@media print': {
      display: 'none',
    },
  },
}));

interface IProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const TopBarPresenter: FC<IProps> = ({ className, onMobileNavOpen, ...rest }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const classes = useStyles();

  return (
    <Toolbar className={clsx(classes.toolbar, className)} {...rest}>
      {/* <Hidden lgUp>
        <IconButton color="inherit" onClick={onMobileNavOpen}>
          <SvgIcon fontSize="small">
            <MenuIcon />
          </SvgIcon>
        </IconButton>
      </Hidden>

      <Hidden mdDown>
        <RouterLink to="/">
          <Logo isWhite={true} width={120} />
        </RouterLink> */}
      <TopBarMenu />
      {/* </Hidden> */}

      {/* <Box flexGrow={1} /> */}
      <AccountMenu />
    </Toolbar>
  );
};

export default TopBarPresenter;
