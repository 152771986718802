import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Page from '@components/page';
import { Theme } from '@src/theme';
import { LogPrefix } from '@utils/log-prefix';

const pageTitle = 'Not Found View';
const log = new LogPrefix(`${pageTitle}\t\t\t-`);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
}));

interface IProps {
  className?: string;
}

const NotFoundView: FC<IProps> = ({ className }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page className={clsx(classes.root, className)} title={`OBRT :: Not Found Page`}>
      <Container maxWidth="lg">
        <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'} color="textPrimary">
          404: The page you are looking for isn’t here
        </Typography>
        <Typography align="center" variant="subtitle2" color="textSecondary">
          You either tried some shady route or you came here by mistake. Whichever it is, try using
          the navigation.
        </Typography>
        <Box mt={6} display="flex" justifyContent="center">
          <img
            alt="Under development"
            className={classes.image}
            src="/static/images/undraw_page_not_found_su7k.svg"
          />
        </Box>
        <Box mt={6} display="flex" justifyContent="center">
          <Button color="secondary" component={RouterLink} to="/" variant="outlined">
            Back to home
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default NotFoundView;
