import * as yup from 'yup';

const EMAIL_MAX = 255;

const NAME_MIN = 3;
const NAME_MAX = 30;

const PASSWORD_MIN = 7;
const PASSWORD_MAX = 100;

const schema = {
  email: yup.string().email('Must be a valid email').max(EMAIL_MAX),
  password: yup.string().min(PASSWORD_MIN).max(PASSWORD_MAX),
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   'Must Contain 8 Characters, One Number and one special case Character',
  // ),
  name: yup.string().min(NAME_MIN).max(NAME_MAX),
  rePassword: yup.string().oneOf([yup.ref('password'), undefined], 'Passwords must match'),
  policy: yup.boolean().oneOf([true], 'This field must be checked'),
};

export const LogInValidation = yup.object().shape({
  email: schema.email.required('Email is required'),
  password: schema.password.required('Password is required'),
  name: schema.name,
});

export const SignUpValidation = yup.object().shape({
  email: schema.email.required('Email is required'),
  password: schema.password.required('Password is required'),
  rePassword: schema.rePassword,
  name: schema.name,
  policy: schema.policy,
});
