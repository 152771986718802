import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Divider,
  Hidden,
  makeStyles,
  Typography,
} from '@material-ui/core';
// import 'quill/dist/quill.snow.css';
import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// import { UserRole } from '@gqls/types';
// import EducationDetailHeader from '@pages/education/detail/EducationDetailHeader';
import Page from '@components/page';
// import useAuth from '@contexts/auth.context';
import { Theme } from '@src/theme';
import { LogPrefix } from '@utils/log-prefix';
import { IEducation, tempObjects } from '@interfaces/education.interface';
// import { quillToHtml } from '@utils/quill-to-html';

const pageTitle = 'Edu Detail Presenter';
const log = new LogPrefix(`${pageTitle}\t--`);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
  },
  contentContainer: {
    minHeight: 'calc(75vh - 64px - 64px)',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    backgroundColor: theme.palette.background.default,
  },
  body: {
    minHeight: 'calc(25vh)',
  },
  cardHeader: {
    minHeight: '80px',
  },
  pdf: {
    height: 'calc(50vh)',
    width: '100%',
  },
}));

interface IProps {
  className?: string;
  eduId: string;
  // eduData: any;
  // onDeleteEdu: any;
}

// const EducationDetailPresenter: React.FC<IProps> = ({ eduData, eduId, onDeleteEdu }) => {
const EducationDetailPresenter: FC<IProps> = ({ eduId }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const classes = useStyles();
  // const { userRole } = useAuth();

  const item: IEducation = tempObjects[eduId];
  // log.info('Education Detail Presenter - data prop', item);

  return (
    <Page className={classes.root} title={`OBRT :: Profile`}>
      <Container maxWidth="lg">
        {/* <EducationDetailHeader category={eduData.category} /> */}

        <Box className={classes.contentContainer}>
          <Card className={classes.content}>
            <Box justifyContent="space-between" display="flex" alignItems="center">
              <Box px={3}>
                <Typography variant="h4" color="primary">
                  <b>{item.title}</b>
                </Typography>
              </Box>

              <CardHeader
                // avatar={<Avatar alt="Person" src={'/static/images/logo.png'} />}
                disableTypography
                subheader={
                  <Box display="flex" alignItems="center" pr={3}>
                    <Typography variant="subtitle1" color="textPrimary">
                      Admin
                    </Typography>
                  </Box>
                }
              />
            </Box>

            <Divider />
            <Box px={3} py={2}>
              {item.videoId && (
                <Box my={2}>
                  <iframe
                    title={item.videoId}
                    width="100%"
                    height="435"
                    src={`https://www.youtube.com/embed/${item.videoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Box>
              )}

              <Hidden xsDown>
                <Box my={2}>
                  {item.pdfUrl && (
                    <iframe
                      title={item.title}
                      className={classes.pdf}
                      src={`${item.pdfUrl}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  )}
                </Box>
              </Hidden>
              {/* Typography 안에 div 넣으면 안됨.*/}
              {/* <Typography
                className={classes.body}
                color="textPrimary"
                dangerouslySetInnerHTML={{
                  __html: eduData.text ? quillToHtml(eduData.text) : '<p> </p>',
                }}
                variant="body1"
              /> */}
            </Box>

            <Divider />
            <Box display="flex" px={3} py={2}>
              {/* <Box pl={2}> */}
              <Button
                color="secondary"
                component={RouterLink}
                to={'/education/list'}
                variant="contained"
              >
                <b>LIST</b>
              </Button>
              {/* </Box> */}

              <Box flexGrow={1} />
              <Box pl={2}>
                {item.pdfUrl && (
                  <Button color="secondary" href={`${item.pdfUrl}`} variant="contained">
                    <b>PDF download</b>
                  </Button>
                )}
              </Box>

              {/* {(userRole === UserRole.Admin || userRole === UserRole.Super) && ( */}
              {/* <Fragment>
                <Box px={2}>
                  <Button
                    color="secondary"
                    component={RouterLink}
                    to={`/education/edit/${eduId}`}
                    variant="contained"
                  >
                    <b>Edit</b>
                  </Button>
                </Box>

                <Box pr={2}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      onDeleteEdu(eduId);
                    }}
                    variant="contained"
                  >
                    <b>Delete</b>
                  </Button>
                </Box>
              </Fragment> */}
              {/* )} */}
            </Box>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default EducationDetailPresenter;
