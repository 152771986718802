import clsx from 'clsx';
import { Field, Form, Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { ChangeEvent } from 'react';
import * as Yup from 'yup';

import { EducationCategoryOptions } from '@constants/education.constant';
import { DEFAULT_EDUCATION_CATEGORY, IEducation } from '@interfaces/education.interface';

import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { LogPrefix } from '@utils/log-prefix';
import { EducationCreateValidation } from '@validation/education.validator';

import { QuillEditor } from '@components/editor/quill-editor';

const pageTitle = 'Education Create Form';
const log = new LogPrefix(`${pageTitle}\t->`);

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    '& .ql-editor': {
      height: 400,
    },
  },
}));

interface IFormProps {
  className?: string;
  onSubmit: (value: any, formikBag: any) => void;
  editor: any;
  // editorText: any;
  // setEditorText: any;
}

const EducationCreateForm: React.FC<IFormProps> = ({
  className,
  onSubmit,
  editor,
  // editorText,
  // setEditorText,
  ...rest
}) => {
  const pageTitle = 'Education Create Form';
  log.info(`${pageTitle}\t- start \t----- ----- ----- ----- ----- ----- ----- -----`);
  const classes = useStyles();

  // useEffect(() => {
  //   if (quill) {
  //     quill.getModule('toolbar').addHandler('image', quillSelectLocalImage);
  //   }
  // }, [quill]);

  // const modules = {
  //   toolbar: {
  //     container: [
  //       [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }, { font: [] }],
  //       [{ size: [] }],
  //       ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  //       [{ list: 'ordered' }, { list: 'bullet' }],
  //       ['link', 'video'],
  //       ['link', 'image', 'video'],
  //       ['clean'],
  //     ],
  //   },
  // };

  const initialValues: Partial<IEducation> = {
    // category: DEFAULT_EDUCATION_CATEGORY,
    title: '',
    // text: '',
    videoId: '',
    // videoThumbnailUrl: '',
  };

  const render = (formikBag) => (
    <Form className={clsx(classes.root, className)} {...rest}>
      {formikBag.errors.submit && (
        <Box mt={1} mb={2}>
          <Alert severity="error">
            <FormHelperText error>{formikBag.errors.submit}</FormHelperText>
          </Alert>
        </Box>
      )}

      <Grid container spacing={3}>
        <Grid item xs={11} lg={10}>
          <Card>
            <CardContent>
              <Box mb={1}>
                <Typography variant="subtitle2" color="textSecondary">
                  Post Title
                </Typography>
              </Box>
              <Field name="title">
                {({ field, meta }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    label="Post Title"
                    // margin="normal"
                    type="title"
                    variant="outlined"
                    error={Boolean(meta.touched && meta.error)}
                    helperText={meta.title && meta.title}
                    {...field}
                  />
                )}
              </Field>

              <Box mt={3} mb={1}>
                <Typography variant="subtitle2" color="textSecondary">
                  Youtube Link
                </Typography>
              </Box>
              <Field name="videoId">
                {({ field, meta }) => (
                  <TextField
                    fullWidth
                    label="Youtube Link"
                    // margin="normal"
                    type="videoId"
                    variant="outlined"
                    error={Boolean(meta.touched && meta.error)}
                    helperText={meta.videoId && meta.videoId}
                    {...field}
                  />
                )}
              </Field>

              <Box mt={3} mb={1}>
                <Typography variant="subtitle2" color="textSecondary">
                  Main Content
                </Typography>
              </Box>
              <Paper variant="outlined">
                <QuillEditor setEditorText={editor.setEditorText} />
              </Paper>

              <Box mt={3} mb={1}>
                <Typography variant="subtitle2" color="textSecondary">
                  Post Thumbnail
                </Typography>
              </Box>
              <Field name="thumbnailUrl">
                {({ field, meta }) => (
                  <TextField
                    fullWidth
                    // margin="normal"
                    // id="thumbnailUrl"
                    type="file"
                    // name="file"
                    variant="outlined"
                    // onChange={(event) => {
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (event.currentTarget.files !== null)
                        formikBag.setFieldValue('thumbnail', event.currentTarget.files[0]);
                      // const file = event.target.files[0];
                      // if (event.currentTarget.files == null) return null;
                      // formikBag.setFieldValue('file', file);
                    }}
                  />
                )}
              </Field>

              {/* <div className="form-group">
                <label htmlFor="file">File upload</label>
                <input
                  id="file"
                  name="file"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (event.currentTarget.files !== null)
                      formikBag.setFieldValue('file', event.currentTarget.files[0]);
                  }}
                  className="form-control"
                />
              </div> */}
            </CardContent>
          </Card>
          {/* <Box mt={3}>
                <Card>
                  <CardHeader title="Upload Images" />
                  <Divider />
                  <CardContent>
                    <EducationPostCard />
                  </CardContent>
                </Card>
              </Box> */}
          {/* <Box mt={3}>
                <Card>
                  <CardHeader title="Prices" />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          error={Boolean(touched.price && errors.price)}
                          fullWidth
                          helperText={
                            touched.price && errors.price
                              ? errors.price
                              : 'If you have a sale price this will be shown as old price'
                          }
                          label="Price"
                          name="price"
                          type="number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.price}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          error={Boolean(touched.salePrice && errors.salePrice)}
                          fullWidth
                          helperText={touched.salePrice && errors.salePrice}
                          label="Sale price"
                          name="salePrice"
                          type="number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.salePrice}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.isTaxable}
                            onChange={handleChange}
                            value={values.isTaxable}
                            name="isTaxable"
                          />
                        }
                        label="Product is taxable"
                      />
                    </Box>
                    <Box mt={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.includesTaxes}
                            onChange={handleChange}
                            value={values.includesTaxes}
                            name="includesTaxes"
                          />
                        }
                        label="Price includes taxes"
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Box> */}
        </Grid>
        <Grid item xs={3} lg={2}>
          <Card>
            <CardHeader title="Category" />
            <CardContent>
              <Field name="category">
                {({ field, meta }) => (
                  <FormControl>
                    {/*<InputLabel id="demo-simple-select-label">Age</InputLabel>*/}
                    <Select
                      fullWidth
                      label="Category"
                      type="category"
                      // variant="outlined"
                      error={Boolean(meta.touched && meta.error)}
                      // labelId="demo-simple-select-label"
                      // id="demo-simple-select"
                      // value={age}
                      // onChange={handleChange}
                      {...field}
                    >
                      {EducationCategoryOptions.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>

              {/*<TextField*/}
              {/*  fullWidth*/}
              {/*  label="Category"*/}
              {/*  name="category"*/}
              {/*  onChange={handleChange}*/}
              {/*  select*/}
              {/*  SelectProps={{ native: true }}*/}
              {/*  value={values.category}*/}
              {/*  variant="outlined"*/}
              {/*>*/}
              {/*  {EducationCategoryOptions.map((item) => (*/}
              {/*    <option key={item.value} value={item.value}>*/}
              {/*      {item.label}*/}
              {/*    </option>*/}
              {/*  ))}*/}
              {/*</TextField>*/}

              {/*<Field name="category">*/}
              {/*  {({ field, meta }) => (*/}
              {/*    <TextField*/}
              {/*      fullWidth*/}
              {/*      label="Category"*/}
              {/*      // margin="normal"*/}
              {/*      type="category"*/}
              {/*      variant="outlined"*/}
              {/*      error={Boolean(meta.touched && meta.error)}*/}
              {/*      helperText={meta.category && meta.category}*/}
              {/*      {...field}*/}
              {/*    >*/}
              {/*      <option value={20}>Twenty</option>*/}
              {/*      <option value={20}>Twenty</option>*/}
              {/*    </TextField>*/}
              {/*  )}*/}
              {/*</Field>*/}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )} */}
      <Box mt={2}>
        <Button
          color="secondary"
          variant="contained"
          type="submit"
          disabled={formikBag.isSubmitting}
        >
          Create Edu
        </Button>
      </Box>
    </Form>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        title: EducationCreateValidation.title,
        category: EducationCreateValidation.category,
      })}
      onSubmit={onSubmit}
    >
      {(props) => render(props)}
    </Formik>
  );
};

export default EducationCreateForm;
