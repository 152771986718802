import { useSnackbar } from 'notistack';
import React, { createContext, FC, ReactNode, useContext, useReducer } from 'react';

import { LogPrefix } from '@utils/log-prefix';

const pageTitle = 'Auth Provider';
const log = new LogPrefix(`${pageTitle}\t\t\t>>STATE<<\t\t\t\t`);

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  accessToken: string | null;
  userEmail: string | null;
  userRole: string | null;
}

export interface AuthContextValue extends AuthState {
  login: (data: any) => void;
  logout: () => void;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    accessToken: string | null;
    userEmail: string | null;
    userRole: string | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    accessToken: string | null;
    userEmail: string | null;
    userRole: string | null;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type Action = InitialiseAction | LoginAction | LogoutAction | RegisterAction;

const initialAuthState: AuthState = {
  isInitialised: false,
  isAuthenticated: false,
  accessToken: null,
  userEmail: null,
  userRole: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, accessToken, userEmail, userRole } = action.payload;

      return {
        ...state,
        isInitialised: true,
        isAuthenticated,
        accessToken,
        userEmail,
        userRole,
      };
    }
    case 'LOGIN': {
      const { accessToken, userEmail, userRole } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        accessToken,
        userEmail,
        userRole,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        userEmail: null,
        userRole: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => {},
  logout: () => {},
});

interface IProps {
  children: ReactNode;
}

export const AuthProvider: FC<IProps> = ({ children }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { enqueueSnackbar } = useSnackbar();

  const login = (data) => {
    const { accessToken, userEmail, userRole } = data;
    localStorage.setItem('jwt', accessToken);
    localStorage.setItem('userEmail', userEmail);
    localStorage.setItem('userRole', userRole);

    enqueueSnackbar(`Logged In`, {
      variant: 'info',
    });

    dispatch({
      type: 'LOGIN',
      payload: {
        accessToken,
        userEmail,
        userRole,
      },
    });
  };

  const logout = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userRole');

    enqueueSnackbar('Logged Out', {
      variant: 'success',
      // anchorOrigin: {
      //   vertical: 'top',
      //   horizontal: 'right',
      // },
    });

    dispatch({
      type: 'LOGOUT',
    });
  };

  const initialize = () => {
    const userEmail = localStorage.getItem('userEmail');
    if (userEmail === null || userEmail === 'undefined') {
      // log.info(`initialize - default`);
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          accessToken: null,
          userEmail: null,
          userRole: null,
        },
      });
    } else {
      // log.info(`initialize - localStorage`);
      const accessToken = localStorage.getItem('jwt');
      const userEmail = localStorage.getItem('userEmail');
      const userRole = localStorage.getItem('userRole');

      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: true,
          accessToken,
          userEmail,
          userRole,
        },
      });
    }
  };

  if (!state.isInitialised) {
    initialize();
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);
export default useAuth;
