import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Breadcrumbs, Button, Grid, Link, Typography, makeStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface IProps {
  className?: string;
}

const EducationCreateHeader: React.FC<IProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link variant="body1" color="inherit" to="/education/list" component={RouterLink}>
            Education
          </Link>
          {/* <Link variant="body1" color="inherit" to="/404" component={RouterLink}>
            Management
          </Link>
          <Typography variant="body1" color="textPrimary">
            Products
          </Typography> */}
        </Breadcrumbs>
        <Typography variant="h3" color="textPrimary">
          Create a new Edu
        </Typography>
      </Grid>
      <Grid item>
        {/* <Button component={RouterLink} to="/education/list"> */}
        <Button component={RouterLink} to="/education">
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default EducationCreateHeader;
