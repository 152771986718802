// import { useLazyQuery, useMutation } from '@apollo/client';
import React from 'react';
// import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { LogPrefix } from '@utils/log-prefix';
// import { GQL } from '@utils/wrap-gql';
// import { DELETE_ONE_EDU_BY_GQL, GET_EDU_BY_ID_GQL } from '@gqls/education.gql';
import EducationDetailPresenter from '@pages/education/detail/education-detail.presenter';

interface IContainerProps {
  id: string;
}

const log = new LogPrefix('Education Detail Container \t-');

const EducationDetailContainer: React.FC<RouteComponentProps<IContainerProps>> = ({
  match,
  ...rest
}) => {
  const _eduId = match.params.id;

  // const history = useHistory();

  // const [eduId, setEduId] = useState(_eduId);

  // const onDeleteEdu = async (deleteEduId) => {
  //   log.info('deleteEduId', deleteEduId);
  //   await deleteEdu({
  //     variables: { eduId: deleteEduId },
  //   }).then((response) => {
  //     history.push('/education/list');
  //     // history.go(0);
  //     // history.goBack();
  //     window.location.reload();

  //     // TODO: refresh 해야 하는 오류 수정
  //   });
  // };

  // const [
  //   getEdu,
  //   { called: getEduCalled, loading: getEduLoading, data: getEduResult },
  // ] = useLazyQuery(GQL(GET_EDU_BY_ID_GQL), {
  //   variables: { eduId: eduId },
  //   onCompleted: (data) => {},
  // });

  // const [deleteEdu] = useMutation(GQL(DELETE_ONE_EDU_BY_GQL));

  // useEffect(() => {
  //   log.info('Education Detail Container - useEffect');

  //   getEdu({ variables: { eduId } });
  // }, [eduId, getEdu]);

  // if (!getEduCalled || getEduLoading || getEduResult === undefined) return null;

  // log.info(`ED Container \t- pass :  `, getEduResult);

  return (
    // <EducationDetailPresenter eduId={eduId} eduData={getEduResult.edu} onDeleteEdu={onDeleteEdu} />
    <EducationDetailPresenter eduId={_eduId} />
  );
};

export default EducationDetailContainer;
