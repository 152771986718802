import { createHttpLink, useMutation } from '@apollo/client';
import axios from 'axios';
import * as log from 'loglevel';
import React, { useEffect, useState } from 'react';
import { useQuill } from 'react-quilljs';
import { useHistory } from 'react-router';

import { CREATE_ONE_EDU_GQL } from '@gqls/education.gql';
import EducationCreatePresenter from '@pages/education/create/education-create.presenter';
// import { extractYoutubeId } from '@utils/extract-youtube-id';
import { GQL } from '@utils/wrap-gql';
import BlotFormatter from 'quill-blot-formatter';

const EducationCreateContainer: React.FC = ({ ...rest }) => {
  const pageTitle = 'Education Create Container';
  log.info(`${pageTitle}\t- start \t----- ----- ----- ----- ----- ----- ----- -----`);
  const [createEduMutation] = useMutation(GQL(CREATE_ONE_EDU_GQL));

  const history = useHistory();

  const [editorText, setEditorText] = useState<any>();

  const onSubmit = async (values, { setErrors, setFieldError, setStatus, setSubmitting }) => {
    log.info(`${pageTitle}\t- onSubmit`);
    log.info(`${pageTitle}\t- onSubmit - values`, values);
    // log.info(`${pageTitle}\t- onSubmit - editorText`, editorText);
    setSubmitting(true);

    let eduId;
    // const youtubeId = extractYoutubeId(values.videoId);

    log.info(`${pageTitle}\t- handleSubmit - values.file : `, values.file);

    let thumbnailImageUrl = '';

    if (values.thumbnail) {
      const formData = new FormData();
      formData.append('thumbnail', values.thumbnail);

      const result = await axios({
        method: 'post',
        url: 'https://obrt.net/files/upload/thumbnail',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      log.info(
        `${pageTitle}\t- onSubmit editorText \t----- ----- ----- ----- ----- ----- ----- -----`,
      );

      thumbnailImageUrl = result.data.filename;

      log.info(thumbnailImageUrl);
    }

    console.log(editorText);
    console.log(values.category);
    console.log(values.title);
    // console.log(youtubeId);

    await createEduMutation({
      variables: {
        input: {
          edu: {
            category: values.category,
            title: values.title,
            text: editorText ? editorText : '{}', // JSON으로 파싱 가능한 string
            // videoId: youtubeId ? youtubeId : '',
            thumbnail: thumbnailImageUrl ? thumbnailImageUrl : '',
          },
        },
      },
    })
      .then((response) => {
        setSubmitting(false);

        log.info(`Education Create Container \t - onSubmit.then.response`, response);

        eduId = response.data.createOneEdu.id;
      })
      .catch((error) => {
        setStatus({ success: false });
        setErrors({ submit: error.msg });
        setSubmitting(false);
        history.push('/404');
      });

    history.push(`/education/${eduId}`);

    // .then((response) => {
    //   log.info(`${pageTitle}\t- handleSubmit - response : `, response);
    //
    //   thumbnailImageUrl = response.data.filename;
    // })
    // .catch((error) => console.log(error));
  };

  return <EducationCreatePresenter editor={{ editorText, setEditorText }} onSubmit={onSubmit} />;
};

export default EducationCreateContainer;
