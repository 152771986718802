import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { GET_EDU_BY_ID_GQL, UPDATE_ONE_EDU_BY_ID_GQL } from '@gqls/education.gql';
// import { checkYoutubeURL, extractYoutubeId } from '@utils/extract-youtube-id';
import { LogPrefix } from '@utils/log-prefix';
import { GQL } from '@utils/wrap-gql';
import axios from 'axios';
import BlotFormatter from 'quill-blot-formatter';
import React, { useState, useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import { useHistory } from 'react-router';
import EducationEditPresenter from '@pages/education/edit/education-edit.presenter';
import { Redirect, RouteComponentProps } from 'react-router-dom';

interface IContainerProps {
  id: string;
}

const log = new LogPrefix('Education Edit Presenter \t-');

const EducationEditContainer: React.FC<RouteComponentProps<IContainerProps>> = ({
  match,
  ...rest
}) => {
  log.info('start');
  const _eduId = match.params.id;
  log.info('match param:', match);
  const history = useHistory();

  const [eduId, setEduId] = useState(_eduId);

  const [editorText, setEditorText] = useState<any>();

  const [
    getEdu,
    { called: getEduCalled, loading: getEduLoading, data: getEduResult },
  ] = useLazyQuery(GQL(GET_EDU_BY_ID_GQL), {
    variables: {
      eduId: eduId,
    },
  });

  const [updateEduMutation] = useMutation(GQL(UPDATE_ONE_EDU_BY_ID_GQL));

  useEffect(() => {
    getEdu({ variables: { eduId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eduId]);

  const onSubmit = async (values, { setFieldError, setStatus, setSubmitting, setErrors }) => {
    log.info('Education Edit Container - onSubmit');
    log.info('Education Edit Container - onSubmit.values:', values);
    setSubmitting(true);

    log.info('Education Edit Container - onSubmit - await editorData before ');

    let eduId;
    let youtubeId;

    let thumbnailImageUrl = '';

    if (values.thumbnail) {
      const formData = new FormData();
      formData.append('thumbnail', values.thumbnail);

      const result = await axios({
        method: 'post',
        url: 'https://obrt.net/files/upload/thumbnail',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      thumbnailImageUrl = result.data.filename;
    }

    // if (checkYoutubeURL(values.videoId)) {
    //   youtubeId = extractYoutubeId(values.videoId);
    // }

    await updateEduMutation({
      variables: {
        eduId: values.id,
        input: {
          title: values.title,
          text: editorText,
          videoId: youtubeId ? youtubeId : '',
          category: values.category,
          thumbnail: thumbnailImageUrl ? thumbnailImageUrl : '',
        },
      },
    })
      .then((response) => {
        setSubmitting(false);

        eduId = response.data.updateOneEdu.id;

        log.info(`Education Edit Container - onSubmit.then.response:`, response);
      })
      .catch((error) => {
        setStatus({ success: false });
        setErrors({ submit: error.msg });
        setSubmitting(false);
        history.push('/404');
      });

    history.push(`/education/${eduId}`);
  };

  if (!getEduCalled || getEduLoading || getEduResult === undefined) return null;
  log.info(`pass -  getEduResult`, getEduResult);

  return (
    <EducationEditPresenter
      onSubmit={onSubmit}
      editor={{ editorText, setEditorText }}
      eduData={getEduResult.edu}
    />
  );
};

export default EducationEditContainer;
