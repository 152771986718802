import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// import useAuth from '@contexts/auth.context';
// import { UserRole } from '@interfaces/user.interface';
import { Theme } from '@src/theme';
import { LogPrefix } from '@utils/log-prefix';

const pageTitle = 'Top Bar';
const log = new LogPrefix(`${pageTitle}\t\t\t\t\t  ㄴㅡ Menu\t\t\t\t`);

const useStyles = makeStyles((theme: Theme) => ({
  fontWeightBold: {
    fontSize: '1.3rem',
    fontWeight: 600,
  },
}));

interface IProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const TopBarMenu: FC<IProps> = ({ className }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const classes = useStyles();

  // const { userRole } = useAuth();
  // const isAdmin = userRole === UserRole.Admin;

  return (
    <Grid container justify="center" alignItems="center" spacing={1}>
      <Grid item>
        <Button
          className={classes.fontWeightBold}
          color="inherit"
          size="large"
          component={RouterLink}
          to="/education"
        >
          작업 중심 회상 활동
        </Button>
      </Grid>
    </Grid>
  );
};

export default TopBarMenu;
