import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Page from '@components/page';
import { Theme } from '@src/theme';
import { LogPrefix } from '@utils/log-prefix';

const pageTitle = 'Forbidden View';
const log = new LogPrefix(`${pageTitle}\t\t\t-`);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
  image: {
    maxWidth: '100%',
    width: 300,
    maxHeight: 300,
    height: 'auto',
  },
}));

interface IProps {
  className?: string;
}

const ForbiddenView: FC<IProps> = ({ className }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page className={clsx(classes.root, className)} title={`OBRT :: Fobidden Page`}>
      <Container maxWidth="lg">
        <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'} color="textPrimary">
          페이지 접근권한이 없습니다.
        </Typography>

        <Typography align="center" variant="subtitle2" color="textSecondary">
          You do not have the correct permissions to view the page.
          <br /> Please request access from your administrator.
        </Typography>

        <Box mt={6} display="flex" justifyContent="center">
          <img alt="Under development" className={classes.image} src="/static/403page_stop.png" />
        </Box>

        <Box mt={6} display="flex" justifyContent="center">
          <Button color="secondary" component={RouterLink} to="/" variant="outlined">
            Back to home
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default ForbiddenView;
