import clsx from 'clsx';
import {
  Box,
  Card,
  CardMedia,
  Divider,
  Grid,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { EducationCategoryKr, IEducation } from '@interfaces/education.interface';
import { Theme } from '@src/theme';
import { LogPrefix } from '@utils/log-prefix';

const pageTitle = 'Education List';
const log = new LogPrefix(`${pageTitle}\t\t\t  ㄴㅡ Card\t\t`);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
  },
  media: {
    height: 200,
  },
  ellipsis: {
    fontWeight: 700,
    fontSize: '1.2rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));
interface IProps {
  post: IEducation;
  className?: string;
}

const EducationListCard: FC<IProps> = ({ post: item, className, ...rest }) => {
  log.info(`start \t----- ----- ----- ----- -----`);

  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box p={3} pt={2}>
        <Box display="flex" alignItems="center" mb={2}>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/education/${item.id}`}
            className={classes.ellipsis}
          >
            {item.title}
          </Link>
        </Box>

        <Link color="textPrimary" component={RouterLink} to={`/education/${item.id}`} variant="h5">
          <CardMedia
            className={classes.media}
            image={
              item.thumbnail ? item.thumbnail : `https://img.youtube.com/vi/${item.videoId}/0.jpg`
            }
          />
        </Link>
      </Box>

      {/* <Divider /> */}

      {/* <Box p={2}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              {EducationCategoryKr[item.category]}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Category
            </Typography>
          </Grid>
        </Grid>
      </Box> */}
    </Card>
  );
};

export default EducationListCard;
