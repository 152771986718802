import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import apolloClient from '@src/lib/apollo-client';
import App from '@components/app';
import { SettingsProvider } from '@src/lib/settings-context';
import { restoreSettings } from '@utils/settings';

const settings = restoreSettings();

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <SettingsProvider settings={settings}>
      <App />
    </SettingsProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
