// v2
export const GET_ALL_EDUS_GQL = `
query GetAllEdus{
  edus {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        title
        views
        videoId
        text
        category
        thumbnail
        createdAt
        updatedAt
      }
    }
  }
}
`;

// v2
export const CREATE_ONE_EDU_GQL = `
mutation CreateOneEdu($input: CreateOneEduInput!) {
  createOneEdu(input: $input) {
    id
    title
    views
    videoId
    text
    category
    thumbnail
    createdAt
    updatedAt
  }
}
`;

// v2
export const GET_EDU_BY_ID_GQL = `
query GetEdusById($eduId: ID!) {
  edu(id: $eduId) {
    id
    title
    views
    videoId
    text
    category
    thumbnail
    createdAt
    updatedAt
  }
}
`;

// v2
export const GET_EDUS_BY_CATEGORY_GQL = `
query GetEdusById($category: EduCategory!, $pagingFirst: Int, $pagingAfterCursor: ConnectionCursor) {
  edus(
    paging: { first: $pagingFirst, after: $pagingAfterCursor }
    filter: { category: { eq: $category } }
    sorting: [{ field: updatedAt, direction: DESC }]
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        title
        views
        videoId
        text
        category
        thumbnail
        createdAt
        updatedAt
      }
    }
  }
}
`;

// v2
export const UPDATE_ONE_EDU_BY_ID_GQL = `
mutation UpdateOneEdu($eduId: ID!, $input: UpdateEdu!) {
  updateOneEdu(input: { id: $eduId, update: $input }) {
    id
    title
    views
    videoId
    text
    category
    thumbnail
    createdAt
    updatedAt
  }
}
`;

// v2
// TODO: return 값 없애기
export const DELETE_ONE_EDU_BY_GQL = `
mutation DeleteOneEdu($eduId: ID!) {
  deleteOneEdu(input: { id: $eduId }) {
    id
  }
}
`;
