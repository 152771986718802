import clsx from 'clsx';
import {
  Box,
  Container,
  // CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Hidden,
} from '@material-ui/core';
import React, { FC, SetStateAction } from 'react';

import EducationListCard from '@pages/education/list/education-list.card';
import EducationListHeader from '@pages/education/list/EducationListHeader';
// import InfiniteScrollbar from '@components/infinite-scrollbar';
import Page from '@components/page';
import { EducationCategoryOptions } from '@constants/education.constant';
import { PageInfo } from '@gqls/types';
import { EducationCategory, tempObjects } from '@interfaces/education.interface';
import { Theme } from '@src/theme';
import { LogPrefix } from '@utils/log-prefix';

const pageTitle = 'Education List';
const log = new LogPrefix(`${pageTitle}\t\t\tㄴㅡ Presenter\t`);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: 'calc(100vh - 64px - 158px)',
  },
  tab: {
    fontSize: '1.0rem',
    fontWeight: 700,
  },
}));

interface IProps {
  className?: string;
  onFetchMore: () => void;
  pageInfo: PageInfo;
  results: any[];
  selectedTab: EducationCategory;
  setSelectedTab: any;
}

const EducationListPresenter: FC<IProps> = ({
  className,
  onFetchMore,
  pageInfo,
  results: objects,
  selectedTab,
  setSelectedTab,
}) => {
  log.info(`start \t----- ----- ----- ----- -----`);
  // log.info(`edusResult : `, objects);

  const classes = useStyles();

  const handleTabsChange = (event: any, value: SetStateAction<EducationCategory>) => {
    setSelectedTab(value);
  };

  return (
    <Page className={clsx(classes.root, className)} title={`OBRT :: ${pageTitle}`}>
      <Container maxWidth="lg">
        <Hidden xsDown>
          <EducationListHeader user={'any'} currentTab={selectedTab} />
        </Hidden>

        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={selectedTab}
            textColor="secondary"
            variant="scrollable"
          >
            {EducationCategoryOptions.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} className={classes.tab} />
            ))}
          </Tabs>
        </Box>
        <Divider />

        <Box mt={3}>
          {/* <InfiniteScrollbar
            height={480}
            emptyNotification={'컨텐츠 준비 중입니다.'}
            handleCallApi={onFetchMore}
            isEmpty={objects.length === 0 && pageInfo.__typename !== undefined}
            isNext={pageInfo.hasNextPage ? pageInfo.hasNextPage : false}
          >
            {pageInfo.__typename === undefined || objects === undefined ? (
              <Box
                width="100%"
                height="20vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            ) : ( */}
          <Grid container spacing={0}>
            {tempObjects.map((item) => (
              <Grid item key={Math.random()} lg={4} md={6} xs={12}>
                <EducationListCard
                  post={{
                    id: item.id,
                    videoId: item.videoId,
                    title: item.title,
                    pdfUrl: item.pdfUrl,
                  }}
                />
              </Grid>
            ))}
          </Grid>
          {/* )}
          </InfiniteScrollbar> */}
        </Box>
      </Container>
    </Page>
  );
};

export default EducationListPresenter;
