export const LOGIN_GQL = `
mutation ($input: LoginInput!) {
    login(input: $input) {
      status
      errorMsg
      data {
        accessToken
        userEmail
        userRole
      }
    }
  }
`;

export const SIGNUP_GQL = `
  mutation ($input: SignUpInput!) {
    signup(input: $input) {
      status
      errorMsg
      data {
        accessToken
        userId
        userEmail
        userName
        userRole
      }
    }
  }
`;

export const GET_ALL_EDUS_GQL = `
  query GetAllEdus($pagingFirst: Int, $pagingAfterCursor: ConnectionCursor) {
    edus(
        paging: { first: $pagingFirst, after: $pagingAfterCursor }
        sorting: [{ field: updatedAt, direction: DESC }]
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          title
          videoUrl
          pdfUrl
          text
          thumbnail
          createdAt
          updatedAt
        }
      }
    }
  }
  
`;

export const GET_EDUS_BY_ID_GQL = `
  query GetEdusById($eduId: ID!) {
    edu(id: $eduId) {
      id
      title
      videoUrl
      pdfUrl
      text
      thumbnail
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_ONE_EDU_GQL = `
  mutation ($input: CreateOneEduInput!) {
    createOneEdu(input: $input) {
      id
      title
      videoUrl
      pdfUrl
      text
      thumbnail
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_ONE_EDU_GQL = `
  mutation ($eduId: ID!, $input: EduInput!) {
    updateOneEdu(input: { id: $eduId, update: $input }) {
      id
      title
      videoUrl
      pdfUrl
      text
      thumbnail
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_ONE_EDU_GQL = `
  mutation ($eduId: ID!) {
    deleteOneEdu(input: { id: $eduId }) {
      id
    }
  }
`;
