import EducationCreateHeader from '@pages/education/create/EducationCreateHeader';
import Page from '@components/page';
import { Container, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import EducationCreateForm from '@pages/education/create/education-create.form';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100,
  },
}));

interface IProps {
  className?: string;
  onSubmit: (value: any, formikBag: any) => void;
  editor: any;
  // editorText: any;
  // setEditorText: any;
}

const EducationCreatePresenter: FC<IProps> = ({ onSubmit, editor }) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title={`OBRT :: Product Create`}>
      <Container maxWidth="lg">
        {/* Old Component */}
        <EducationCreateHeader />
        <EducationCreateForm
          onSubmit={onSubmit}
          editor={editor}
          // editorText={editorText}
          // setEditorText={setEditorText}
        />
      </Container>
    </Page>
  );
};

export default EducationCreatePresenter;
